/* Global css */

* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

html[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

html[dir="ltr"] {
  direction: ltr;
  text-align: left;
}
/* Apply background gradient to the body */
/* body {
  background-image: linear-gradient(330deg, #ca0000 64%, #9e0001 58%);
  background-color: #ca0000;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
} */

*::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}

.MuiPickersBasePicker-container {
  background-color: black;
}

.timePicker {
  background-color: rgba(255, 255, 255, 0.5) !important;
  width: 100%;
  border-radius: 12px !important;
  text-align: left;
}

.timePicker .MuiInputBase-input {
  padding: 10px !important;
  color: white !important;
  height: 2.5rem !important;
  font-size: 1.2rem !important;
}
.timePicker .MuiOutlinedInput-root fieldset {
  border: none !important;
}
.timePicker .MuiInputBase-input::placeholder {
  color: white !important;
  font-size: 1rem !important;
}

.timePicker .MuiInputBase-root.Mui-error {
  border: none !important;
}

.timePicker .MuiFormHelperText-root.Mui-error {
  color: #fff !important;
}

.timePicker .MuiInputLabel-root {
  font-size: 1.2rem !important;
}

.lightTimePicker {
  border: 1px solid black !important;
  border-radius: 0px !important;
  height: 31px !important;
  overflow: hidden !important;
  width: 99% !important;
  margin-top: 2px !important;
  padding: 0 !important;
}
.lightTimePicker .MuiInputBase-input {
  color: black !important;
  font-size: 1rem !important;
  padding: 0 !important;
}

.lightTimePicker .MuiOutlinedInput-root fieldset {
  border: none !important;
}
.lightTimePicker .MuiInputBase-input::placeholder {
  color: black !important;
  font-size: 1rem !important;
}

.lightTimePicker .MuiInputBase-root.Mui-error {
  border: none !important;
}

.lightTimePicker .MuiFormHelperText-root.Mui-error {
  color: #000 !important;
}

.lightTimePicker .MuiInputLabel-root {
  font-size: 1rem !important;
}

.relative_container {
  position: relative;
}

.eye_icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 68%;
  transform: translateY(-50%);
}

.eye_icon_change {
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 60%;
  transform: translateY(-50%);
}
.eye_admin_icon_change {
  cursor: pointer;
  position: absolute;
  right: 45px;
  top: 28%;
  transform: translateY(-50%);
}

@media (min-width: 600px) {
  .timePicker .MuiInputBase-input {
    height: 1.6rem !important;
  }
}

.MuiPickersLayout-root.css-1g7nc1s-MuiPickersLayout-root ul {
  border: 0.2px solid #fd4958;
}

.MuiPickersLayout-root.css-1g7nc1s-MuiPickersLayout-root li {
  color: black;
}

.MuiPickersToolbar-toolbar {
  background-color: black;
}

.MuiPickersLayout-root {
  background-color: white;
}

.MuiDialog-paper {
  margin: 0 !important;
}

.customDatePicker {
  background-color: transparent !important;
  width: 100%;
  overflow: hidden;
}

.customDatePicker2 .MuiInputBase-input {
  width: 100% !important;
  padding: 0px !important;
  height: 2rem !important;
  font-size: 12px !important;
}

.customDatePicker .MuiInputBase-input {
  padding: 10px;
  border-radius: 12px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: white !important;
  height: 2.5rem !important;
  font-size: 1.2rem !important;
  margin-right: -14px;
}

.customDatePicker .MuiOutlinedInput-root fieldset {
  border: none !important;
}

.customDatePicker .MuiInputBase-input:focus {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.customDatePicker .MuiInputBase-input::placeholder {
  color: white !important;
  font-size: 1rem !important;
}

.customDatePicker .MuiInputBase-root.Mui-error {
  border: none !important;
}
.customDatePicker .MuiFormHelperText-root.Mui-error {
  color: #fff !important;
}

.customDatePicker .MuiInputLabel-root {
  font-size: 1.2rem !important;
}

.customDatePicker .MuiSvgIcon-root {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 2.5rem;
}

.lightCustomDatePicker .MuiInputBase-input {
  padding: 10px;
  border-radius: 12px !important;
  color: black !important;
  height: 2.5rem !important;
  font-size: 1.2rem !important;
  margin-right: -14px;
}

.lightCustomDatePicker .MuiOutlinedInput-root fieldset {
  border: none !important;
}

.lightCustomDatePicker .MuiInputBase-input::placeholder {
  color: #000 !important;
  font-size: 1rem !important;
}

.lightCustomDatePicker .MuiInputBase-root.Mui-error {
  border: none !important;
}
.lightCustomDatePicker .MuiFormHelperText-root.Mui-error {
  color: #000 !important;
}

.lightCustomDatePicker .MuiInputLabel-root {
  font-size: 1.2rem !important;
}

.lightCustomDatePicker .MuiSvgIcon-root {
  color: black !important;
  font-size: 2.5rem;
}

.MuiPickersCalendarHeader-label {
  color: black !important;
}
.MuiMenu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  top: 280;
  background-color: #ca0000;
}
.MuiFormHelperText-root {
  position: absolute;
  bottom: -20px;
  left: 5px;
  padding: 0 !important;
  margin: 0 !important;
}

.MuiTypography-h6 {
  word-break: break-word;
}
.MuiTypography-body2 {
  margin-bottom: 5px !important;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.otp-label {
  text-align: start;
  display: flex;
  align-self: self-start;
}

.otp-input-item {
  width: 23% !important;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: none;
  outline: none;
  border-radius: 12px;
  text-align: center;
  color: #fff;
}

.checkbox-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

input[type='file']::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}
.Mui-focused {
  color: black !important;
}
input[type='file']::file-selector-button:hover {
  background-color: #ff7878;
}

input[type='file']::file-selector-button:active {
  background-color: #ff7878;
}

.hyper_link {
  color: #fff;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='checkbox'] {
  accent-color: rgb(147, 0, 0) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.absolute-item {
  position: absolute;
  right: 0;
  top: -10px;
}
.MuiMenu-list {
  max-height: 100px;
}
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

.bottom-text {
  text-align: left;
  margin-top: -14px;
}

.MuiFormControl-marginNormal {
  margin-top: 0 !important;
}

.MuiAccordionSummary-root {
  margin-bottom: 10px !important;
}

.MuiInputBase-multiline {
  padding: 4px 0 !important;
}

.MuiMultiSectionDigitalClockSection-item {
  color: black !important;
}

.navigation-links {
  color: white !important;
}
.anchor-link {
  text-decoration: underline;
  color: white;
}
.reset-link {
  margin-top: -12px;
  display: flex;
  justify-content: flex-end;
}

.otp-resend {
  display: flex;
  justify-content: flex-end;
  margin-top: -12px;
}

.underline-text {
  cursor: pointer;
}

.timer {
  text-decoration: none !important;
}

.absolute-index {
  z-index: -1;
}

.tap-step-container {
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  font-size: 1.6rem;
  cursor: pointer;
}

.tap-step-selected {
  background-color: #fff;
  color: black;
}
.full-width {
  width: 100% !important;
}
.MuiDatePickerToolbar-title {
  color: black !important;
}
.captcha-item div iframe .rc-anchor-normal {
  width: 100% !important;
}
@media (min-width: 1200px) {
  .css-18hmsz6-MuiStack-root {
    flex-direction: column !important;
  }
}

.MuiDialog-container {
  width: 400px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .MuiDialog-container {
    width: 90% !important;
    margin: 0 auto;
  }
}

@media (min-width: 600px) {
  .customDatePicker .MuiInputBase-input {
    height: 1.6rem !important;
  }

  .customDatePicker2 .MuiInputBase-input {
    width: '40%';
  }
}

@media (min-width: 370px) {
  .customDatePicker .MuiInputBase-input {
    width: 100%;
  }
}
.small-date-picker {
  min-width: 100% !important;
}
.small-date-picker .MuiInputBase-input {
  padding: 20px 0 20px 5px !important;
  height: 40px !important;
  box-sizing: border-box;
}

.admin-dashboard .dash-groups {
  background-color: white;
}
.MuiClock-squareMask {
  background-color: #ca0000 !important;
}
